import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="I’m Amala and I’m a Backend & Devops engineer!" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>More about me</h1>
          <p>
          Apart from being a web developer, I enjoy most of my time being outdoors. In the free time, I liked to travel with my friends and have food from different restuarents.

When forced indoors, I follow a number of movies and television shows, I intersted in drawing, I am an aspiring fashion designer, and I spend a large amount of my free time exploring
the latest technolgy advancements in the front-end web development world.
          </p>
          <Button as={AnchorLink} href="#contact">
            Let's talk
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
